export async function scrollUp({ meterSelector = '.scroll-up-meter', buttonSelector = '.scroll-up' } = {}) {
    let meter = document.querySelector(meterSelector),
        button = document.querySelector(buttonSelector);

    if (meter && button) {
        let { initScrollUp } = await import(/* webpackMode: "lazy" */ './inc/scroll-up.async');

        initScrollUp({ meter, button });
    }
}
