import { oldBrowserCheck } from '@spinnwerk/old-browser-check';

export function oldBrowserAlert({ selector = '.browser-alert--browsehappy' } = {}) {
    const element = document.querySelector(selector),
        oldBrowser = oldBrowserCheck({
            ie: '12',
            firefox: '60',
            chrome: '66',
            opera: '50',
            safari: '11',
            android: '7.0',
        });

    if (oldBrowser && element) {
        element.style.display = 'block';
    }
}
