import { eventListenerOptions } from '@spinnwerk/polyfills';
import { toElements } from '../utils';

/**
 * Adds a class for fading out the page when leaving.
 *
 * @param {object} options
 * @param {string|HTMLElement|NodeList|Array<string|HTMLElement|NodeList>} [options.ignoreClicksOn='a&#91;href^="mailto"&#93;, a&#91;href\^="tel"&#93;, a&#91;download&#93;, .download, a&#91;href\^="javascript:"&#93;'] - Which elements should not trigger the page fading.
 * @param {boolean} [options.extendDefault=false] - Whether to extend the default value of <code>options.ignoreClicksOn</code> or override it.
 * @param {string} [options.className='body--fade-out'] - The class to set to the <code>body</code>-element when leaving and the page should fade.
 */
export function fadePage({ ignoreClicksOn = '', extendDefault = false, className = 'body--fade-out' } = {}) {
    let ignoreOnBeforeUnload = false,
        defaultIgnoredElements = 'a[href^="mailto"], a[href^="tel"], a[download], .download, a[href^="javascript:"]',
        ignoredElements = [extendDefault ? defaultIgnoredElements : '', ignoreClicksOn || defaultIgnoredElements];

    // fade out on page leave
    toElements(ignoredElements).forEach(element => {
        element.addEventListener(
            'click',
            () => {
                ignoreOnBeforeUnload = true;
            },
            eventListenerOptions({ passive: true }),
        );
    });

    window.addEventListener(
        'beforeunload',
        () => {
            if (!ignoreOnBeforeUnload) {
                document.body.classList.add(className);
            }

            ignoreOnBeforeUnload = false;
        },
        eventListenerOptions({ passive: true }),
    );

    window.addEventListener('pageshow', event => {
        if (event.persisted) {
            document.body.classList.remove(className);
        }
    });
}
