async function headroom(options) {
    let { Headroom } = await import(/* webpackMode: "lazy" */ './headroom');

    return new Headroom(options);
}

async function sectionNav(options) {
    let { SectionNav } = await import(/* webpackMode: "lazy" */ './section-nav');

    return new SectionNav(options);
}

import { bugsnag } from './bugsnag';
import { clearAway } from './clear-away';
import { fadePage } from './fade-page';
import { filter } from './filter';
import { serviceWorker } from './service-worker';
import { scrollAnchors } from './scroll-anchors';
import { scrollUp } from './scroll-up';
import { showFocusOnTabKey } from './show-focus-on-tab-key';

export {
    bugsnag,
    clearAway,
    fadePage,
    filter,
    headroom,
    sectionNav,
    serviceWorker,
    scrollAnchors,
    scrollUp,
    showFocusOnTabKey,
};
