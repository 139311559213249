/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, remove the corresponding function calls from here.
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_includes/foundation';

foundation.init();

// ////////////////////////////////////////////////////////////
// Application setup
import * as application from './lib/application';

// register service worker in production mode
application.serviceWorker();

// ux / accessibility features
application.showFocusOnTabKey();
application.fadePage();
application.scrollUp();

// ////////////////////////////////////////////////////////////
// init and config GA
// if (CONFIG.gtm_code) {
//     require(['./lib/analytics/cf7']);
// }

application.headroom({
    offset: 10,
    tolerance: 0,
    onload(headroom) {
        headroom.show();
    },
});

// update url based on scrolling
// application.sectionNav({
//     scrollFromHashChanges: CONFIG.scrollFromHashChanges || false,
//     getHash(el) {
//         return '/' + (el.getAttribute('data-section-nav-anchor') || el.id);
//     },
//     getActiveStateSelector(hash) {
//         return `[href="#${hash.replace(/^#?\//, '')}"]`;
//     },
//     getAttribute(hash) {
//         return hash.replace(/#\/?/, '');
//     },
// });

// application.clearAway({
//     target: '.section-nav',
//     trigger: '.footer',
//     className: 'section-nav--cleared-away',
// });

application.scrollAnchors();

// filter
// application.filter();

// ////////////////////////////////////////////////////////////
// Analytics
// import * as analytics from './lib/analytics';
// analytics.cf7();

// ////////////////////////////////////////////////////////////
// Effects
// import * as effects from './lib/effects';
//
// effects.animateSVG();
// effects.counter();

// ////////////////////////////////////////////////////////////
// form helpers
// import * as form from './lib/form';
//
// // prevent mobile zoom in
// form.preventMobileZoom();
//
// function initFormHelpers() {
//     // automatically attach a loading indicator to submit buttons on form submit
//     form.loadingIndicator();
//
//     // init floating-label-inputs
//     form.floatingLabel();
//
//     // enable validation
//     form.validation();
//
//     // enable (de-)selecting all checkboxes at once
//     form.selectAll();
//
//     // style advanced file inputs
//     form.fileInput({
//         multipleFilesLabel: ':anz Dateien ausgewählt',
//     });
//
//     // enable multi file uploads
//     form.multiUpload();
// }
//
// initFormHelpers();

// woocommerce replaces DOM completeley, so all functionality must be applied again (-> missing event handlers)
// import { eventListenerOptions } from '@spinnwerk/polyfills';
// document.body.addEventListener('updated_wc_div', () => initFormHelpers(), eventListenerOptions({ passive: true }));

// ////////////////////////////////////////////////////////////
// layouts
import * as layouts from './lib/layouts';

layouts.masonry();
// layouts.swiper();
// layouts.map();

// ////////////////////////////////////////////////////////////
// browser alerts
import * as alerts from './lib/alerts';

alerts.oldBrowserAlert();
