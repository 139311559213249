import { toElements } from '../utils';

/**
 * Scroll to anchor targets instead of jumping
 *
 * @param {object} [options]
 * @param {string|string[]|HTMLElement|NodeList} [options.scrollAnchors='a&#91;href*="#"&#93;:not(&#91;href="#"&#93;):not(.no-scroll)'] - One or more elements or selectors for selecting the anchors to animate scrolling from
 * @param {boolean} [options.extendDefault=false] - Whether to extend or overwrite the default {@link #scrollAnchors}
 *
 * @returns {Promise<void>}
 */
export async function scrollAnchors({ scrollAnchors = '', extendDefault = false } = {}) {
    const defaultSelector = 'a[href*="#"]:not([href="#"]):not(.no-scroll)';

    let anchors = toElements([extendDefault ? defaultSelector : null, scrollAnchors || defaultSelector]);

    if (CONFIG.scrollFromHashChanges !== true) {
        const $ = await import('jquery');

        anchors.forEach(anchor => {
            new Foundation.SmoothScroll($(anchor));
        });
    } else {
        let { initScrollAnchors } = await import(/* webpackMode: "lazy" */ './inc/scroll-anchors.async');

        initScrollAnchors({ anchors });
    }
}
